
































































































  import axios2 from '@/axios2';
  import Vue from 'vue';
  import Vuex from 'vuex';
  import { baseApiUrl, userKey } from '@/global';
import { UserService } from '@/code/services/user/userService';
import localStorageUtil from '@/code/util/localStorageUtil';
import { AxiosRequestConfigExtended } from '@/code/interfaces/AxiosRequestConfigExtended';

  Vue.config.devtools = true
  Vue.use(Vuex)

  export default Vue.extend({
    name: 'PagesLogin',
    components: {
      PagesBtn: () => import('@/components/pages/Btn.vue'),
    },
    data() {
      return {
        loading: false,
        login: '',
        password: '',
        authError: false,
        authErrorInfo: '',
        indeterminate: true
      }
    },
    computed: {
      notLoading () : Boolean {
        return !this.loading
      },
    },
    mounted () {
      const that = this
      window.addEventListener('keyup', (event) : void => {
        if (event.key === 'Enter') {
          that.doLogin()
        }
      })
    },
    methods: {
      doLogin: function() : void {
        const userService = new UserService();
        userService.logout('');
        this.loading = true
        this.authError = false
        this.authErrorInfo = ''
        const params = { login: this.login, password: this.password }
        // delete axios.defaults.headers.common['X-Requested-With']
        // axios.defaults.headers.common['Content-Type'] = 'application/json'
        const myConfig: AxiosRequestConfigExtended = { handleError: false };
        axios2.post(`auth/login`, params, myConfig)
          .then((response: any) => {
            if (response.status !== 200) {
              if (response.response && response.response.data && response.response.data.message) {
                this.showError(response.response.data.message);
                return;
              } else {
                this.showError('A requisição de login retornou com um status de erro inexperado.\nAlgumas causas podem ser:\n1. Dados de Login errados\n2. Problema com sua conexão ou navegador\n3. Serviço encontra-se momentaneamente instável')
                return;
              }
            }

            const token = response.data.data.accessToken;

            this.$store.commit('setLoggedUser', params.login)
            this.$store.commit('setToken', token)
            //localStorage.setItem(userKey, token)
            localStorageUtil.login(token);

            var userService = new UserService;
            userService.getCurrentUser()
            .then(response => {
              setTimeout(() => {
                this.$store.commit('setAuthenticated', true)
                this.$router.replace({ name: 'Dashboard' })
              }, 2000);
            });
          })
          .catch( e => {
            console.log(params)
            this.showError('Login ou Senha inválidos!')
          })
      },
      showError (error: string) : void {
        setTimeout(() => {
          this.loading = false
          this.authError = true
          this.authErrorInfo = error
        }, 2000)
      },
    },
  });
